import React from "react";
import { Row, Col } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import { variantPolo } from "@utils/static-data";

import styles from "./variant.module.scss";

const VariantPolo = () => {
    const images = useStaticQuery(graphql`
        {
            allFile(
                filter: {
                    dir: { regex: "/(products)/" }
                    name: { regex: "/(kaos-polo)/" }
                }
                sort: { fields: name }
            ) {
                edges {
                    node {
                        id
                        base
                        childImageSharp {
                            fluid(maxHeight: 130, quality: 80) {
                                aspectRatio
                                base64
                                sizes
                                src
                                srcSet
                            }
                        }
                    }
                }
            }
        }
    `);

    const renderVariantPolo = () => {
        const view = [];
        for (let i in variantPolo) {
            view.push(
                <Col key={i} xs={6} md={2} className={styles.poloItems}>
                    <Img
                        fluid={
                            images.allFile.edges[i].node.childImageSharp.fluid
                        }
                        className={styles.poloImg}
                        objectFit="contain"
                        imgStyle={{ objectFit: "contain" }}
                        alt="variant-polo"
                    />
                    <span
                        className={styles.poloName}
                        dangerouslySetInnerHTML={{
                            __html: variantPolo[i].name,
                        }}
                    />
                </Col>
            );
        }

        return view;
    };

    return (
        <section className="product-list mb-5">
            <h2 className={styles.title}>
                #Konveksi Polo Shirt Online No.1 Di Indonesia
            </h2>
            <Row>{renderVariantPolo()}</Row>
        </section>
    );
};

export default VariantPolo;
